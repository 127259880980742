angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('endSign', {
            url: '/sign/end',
            views: {
                "content": {
                    controller: 'EndSignCtrl',
                    templateUrl: 'web/sign/endSign.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Document Signed by Fingerink'
            }
        });
    })

    .controller('EndSignCtrl', function ($rootScope, $scope, signatureService, $stateParams, $timeout, $translate, companyService, $state) {
        var that = this;
        that.domain = $rootScope.dominio;
        that.aceptar = function () {
            window.location = "https://" + that.domain;
        };
    });